(function($) {

	var $form = null;

	function show(text) {
		$form.addClass('ordering-form--visible');
		$('#static-field').html(text);
		$('#static-field-input').val(text);
	}

	function hide() {
		$form.removeClass('ordering-form--visible');
		$form.find('.dialog').removeClass('dialog--sent');
	}

	$.fn.initOrderingForm = function() {

		var _this = this;

		$('.order-button').click(function() {
			show($(this).attr('data-text'));
			return false;
		});

		return _this.each(
			function() {
				$form = $(this);

				$form.find('.overlay').click(function() {
					hide();
					return false;
				});

				$form.find('.closer').click(function() {
					hide();
					return false;
				});

				$form.find('.input').blur(function() {
					$(this).addClass('input--touched');
				})

				$form.find('form').submit(function() {

					$form.find('.input').addClass('input--touched');

					$('#ordering-form-submit').addClass('submit-button--working');

					$(this)
						.ajaxSubmit({
							success: function (response) {

								if (!response.success) {
									$('#ordering-form-submit').removeClass('submit-button--working');
									if (response.message) {
										window.alert(response.message);
									} else {
										window.alert('Nastala chyba. Zkuste to prosím znovu, pokud problém přetrvá, kontaktujte nás e-mailem.');
									}
									return;
								}

								$('#ordering-form-submit').removeClass('submit-button--working');
								$form.find('.dialog').addClass('dialog--sent');
								$form.find('.input').removeClass('input--touched');
							},
							error: function (a) {

								$form.find('.input').removeClass('input--touched');

								var errorDetailsGiven = false;
								if (a.responseText) {
									try {
										var parsed = JSON.parse(a.responseText);
										if (parsed && parsed.message) {
											errorDetailsGiven = true;
											window.alert(parsed.error);
										} else {
											window.alert('Nastala chyba. Zkuste to prosím znovu, pokud problém přetrvá, kontaktujte nás e-mailem.');
										}
										return;
									} catch (e) {
										;

									}
									if (!errorDetailsGiven && a.responseText.length < 500) {
										errorDetailsGiven = true;
										alert(a.responseText);
										return;
									}
								}
								if (!errorDetailsGiven) {
									if (a.statusText) {
										alert(a.statusText);
									} else {
										alert('Nastala chyba při odesílání...');
									}
									return;
								}

								$('#ordering-form-submit').removeClass('submit-button--working');

							}
						})
					;

					return false;

				});

				$form.find('#thank-you-button').click(function() {
					hide();
				});


			}
		);


	};

	$.orderingForm = {
		show: function(dateDetails) { show(dateDetails);} ,
		hide: hide,
	};

})(jQuery);
